import React, { useState, createContext, useEffect } from 'react';
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
} from 'firebase/auth';
import { doc, setDoc, getDoc, getDocs, collection } from 'firebase/firestore';
import { auth, db } from '../firebase';
import Swal from 'sweetalert2';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [userData, setUserData] = useState(null);
	const [updated, setUpdated] = useState(true);
	const [products, setProducts] = useState([]);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [openCart, setOpenCart] = useState(false);

	useEffect(() => {
		getUserData(user);
	}, [user]);

	const getUserData = async (user) => {
		if (user) {
			const document = await getDoc(doc(db, 'users', user.uid));
			setUserData(document.data());
		}
	};

	useEffect(() => {
		updated && setUpdated(false);
		fetchAllProducts()
			.then((products) => {
				setProducts(products);
			})
			.catch((error) => {
				throw error;
			});
	}, [updated]);

	useEffect(() => {
		if (user) {
			updated && setUpdated(false);
			fetchAllUsers()
				.then((users) => {
					setUsers(users);
				})
				.catch((error) => {
					throw error;
				});
		}
	}, [updated, user]);

	useState(() => {
		loading && setLoading(false);
	}, [loading]);

	const login = async (Email, Password) => {
		setLoading(true);
		try {
			await signInWithEmailAndPassword(auth, Email, Password).then(() => {
				setLoading(false);
				window.location.href = '/';
			});
		} catch (e) {
			setLoading(false);
			let message = '';

			if (e.code === 'auth/user-not-found') {
				message = 'User not found';
			} else if (e.code === 'auth/wrong-password') {
				message = 'Wrong password';
			} else if (e.code === 'auth/invalid-email') {
				message = 'Invalid email';
			} else if (e.code === 'auth/too-many-requests') {
				message = 'Too many requests';
			} else if (e.code === 'auth/user-disabled') {
				message = 'User disabled';
			} else if (e.code === 'auth/network-request-failed') {
				message = 'Network request failed';
			} else if (e.code === 'auth/weak-password') {
				message = 'Weak password';
			} else if (e.code === 'auth/email-already-in-use') {
				message = 'Email already in use';
			} else if (e.code === 'auth/operation-not-allowed') {
				message = 'Operation not allowed';
			} else {
				message = 'Something went wrong';
			}

			return Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: `${message}`,
			});
		}
	};

	const register = async (Name, Email, Password) => {
		setLoading(true);
		try {
			await createUserWithEmailAndPassword(auth, Email, Password).then(() => {
				setDoc(doc(db, 'users', auth.currentUser.uid), {
					Name,
					Email,
					Kind: 'user',
					Date: new Date(),
				}).then(() => {
					setLoading(false);
					window.location.href = '/';
				});
			});
		} catch (e) {
			setLoading(false);
			let message = '';
			if (e.code === 'auth/weak-password') {
				message = 'Weak password';
			} else if (e.code === 'auth/email-already-in-use') {
				message = 'Email already in use';
			} else if (e.code === 'auth/operation-not-allowed') {
				message = 'Operation not allowed';
			} else if (e.code === 'auth/invalid-email') {
				message = 'Invalid email';
			} else if (e.code === 'auth/network-request-failed') {
				message = 'Network request failed';
			} else if (e.code === 'auth/too-many-requests') {
				message = 'Too many requests';
			} else if (e.code === 'auth/user-disabled') {
				message = 'User disabled';
			} else {
				message = 'Something went wrong';
			}

			return Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: `${message}`,
			});
		}
	};

	const forgot = async (email) => {
		setLoading(true);
		try {
			await sendPasswordResetEmail(auth, email).then(() => {
				setLoading(false);
				return Swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Check your email for further instructions',
				});
			});
		} catch (e) {
			setLoading(false);
			let message = '';
			if (e.code === 'auth/invalid-email') {
				message = 'Invalid email';
			} else if (e.code === 'auth/user-not-found') {
				message = 'User not found';
			} else if (e.code === 'auth/too-many-requests') {
				message = 'Too many requests';
			} else if (e.code === 'auth/network-request-failed') {
				message = 'Network request failed';
			} else if (e.code === 'auth/user-disabled') {
				message = 'User disabled';
			} else {
				message = 'Something went wrong';
			}

			return Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: `${message}`,
			});
		}
	};

	const logout = async () => {
		setLoading(true);
		try {
			await signOut(auth).then(() => {
				setLoading(false);
				window.location.href = '/';
			});
		} catch (e) {
			setLoading(false);
			return Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: `${e.message}`,
			});
		}
	};

	const fetchAllProducts = async () => {
		try {
			const productsCollection = collection(db, 'products');
			const querySnapshot = await getDocs(productsCollection);

			const products = [];

			querySnapshot.forEach((doc) => {
				products.push({ id: doc.id, ...doc.data() });
			});

			return products;
		} catch (error) {
			throw error;
		}
	};

	const fetchAllUsers = async () => {
		try {
			const usersCollection = collection(db, 'EmailList');
			const querySnapshot = await getDocs(usersCollection);

			const users = [];

			querySnapshot.forEach((doc) => {
				users.push({ id: doc.id, ...doc.data() });
			});

			return users;
		} catch (error) {
			throw error;
		}
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				login,
				register,
				forgot,
				logout,
				userData,
				updated,
				setUpdated,
				products,
				users,
				loading,
				setLoading,
				openCart,
				setOpenCart,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
