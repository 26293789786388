import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useContext,
} from 'react';
import './styles/cart.css';
import { IoCloseSharp } from 'react-icons/io5';
import { AuthContext } from '../navigation/AuthProvider';

import CartItem from './CartItem';
import Swal from 'sweetalert2';

const useMountedState = () => {
	const mountedRef = useRef(false);
	const isMounted = useCallback(() => mountedRef.current, []);

	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, [isMounted]);

	return isMounted;
};

const Cart = ({ status, close, goToCheckOut }) => {
	const isMounted = useMountedState();
	const [cart, setCart] = useState([]);
	const [total, setTotal] = useState(0);
	const { updated, setUpdated, products } = useContext(AuthContext);

	useEffect(() => {
		const body = document.querySelector('body');
		if (status) {
			body.classList.add('cart-open');
			setUpdated(true);
		} else {
			body.classList.remove('cart-open');
		}
		// eslint-disable-next-line
	}, [status]);

	const onIncrease = (item) => {
		let id = item.id;
		const prod = products.find((product) => product.id === id);
		let cart = JSON.parse(localStorage.getItem('cart'));

		if (cart !== null) {
			let item = cart.find((item) => item.id === id);

			if (item.Quantity >= prod.Quantity) {
				Swal.fire({
					icon: 'info',
					title: 'Oops...',
					text:
						'Sorry, we only have ' + prod.Quantity + ' items left in stock!',
				});
			} else {
				item.quantity += 1;
				localStorage.setItem('cart', JSON.stringify(cart));
				setUpdated(true);
			}
		}
	};

	const onDecrease = (item) => {
		let id = item.id;

		let cart = JSON.parse(localStorage.getItem('cart'));
		if (cart !== null) {
			let item = cart.find((item) => item.id === id);
			if (item.quantity > 1) {
				item.quantity -= 1;
				localStorage.setItem('cart', JSON.stringify(cart));
				setUpdated(true);
			} else {
				onRemove(id);
			}
		}
	};

	const onRemove = (item) => {
		let id = item.id;
		let cart = JSON.parse(localStorage.getItem('cart'));
		if (cart !== null) {
			let item = cart.find((item) => item.id === id);
			let index = cart.indexOf(item);
			cart.splice(index, 1);
			localStorage.setItem('cart', JSON.stringify(cart));
			setUpdated(true);
		}
	};

	const loadCart = () => {
		let cart = JSON.parse(localStorage.getItem('cart'));

		if (cart === null) {
			setCart([]);
			setTotal(0);
		} else {
			isMounted() && setCart(cart);
			setTotal(cart.reduce((acc, item) => acc + item.price * item.quantity, 0));
		}
	};

	useEffect(() => {
		updated && setUpdated(false);
		loadCart();
		// eslint-disable-next-line
	}, [updated]);

	const onCheckOut = (cart) => {
		if (cart.length > 0) {
			close();
			goToCheckOut(cart);
		} else {
			close();
			return Swal.fire({
				icon: 'info',
				title: 'Oops...',
				text: 'Your cart is empty!',
			});
		}
	};

	return (
		<section className='cart-bar'>
			<div className='main'>
				<div className='cart-header'>
					<h2>Cart</h2>
					<IoCloseSharp className='close-icon' onClick={close} />
				</div>
				<div className='cart-body'>
					{cart.length > 0 ? (
						cart.map((item) => (
							<CartItem
								key={item.id}
								product={item}
								onAdd={() => onIncrease(item)}
								onRemove={() => onDecrease(item)}
							/>
						))
					) : (
						<div className='empty'>
							<h3>Your cart is empty</h3>
							<h4 onClick={() => setUpdated(true)}>Reload</h4>
						</div>
					)}
				</div>
				<div className='cart-footer'>
					<div className='price'>
						<h3>Sub Total</h3>
						<h3>R {total}</h3>
					</div>
					<p>Shipping, taxes, and discounts calculated at checkout.</p>
					<div className='checkout' onClick={() => onCheckOut(cart)}>
						Checkout
					</div>
				</div>
			</div>
		</section>
	);
};

export default Cart;
