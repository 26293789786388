import React, { useEffect, useState } from 'react';
import './styles/item.css';

const Item = ({ id, name, price, img, qty, onPress }) => {
	const [inStock, setInStock] = useState();

	useEffect(() => {
		if (qty === 0) {
			setInStock(false);
		} else {
			setInStock(true);
		}
	}, [qty]);

	return (
		<div className='item' onClick={onPress} key={id}>
			{!inStock && (
				<div className='sold-out'>
					<span>Sold Out</span>
				</div>
			)}

			<div className={!inStock ? 'product-image sold' : 'product-image'}>
				<img src={img} alt='product' />
			</div>
			<div className='product-info'>
				<h3>{name}</h3> <span>R {price}</span>
			</div>
		</div>
	);
};

export default Item;
