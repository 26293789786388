import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useContext,
} from 'react';

import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../navigation/AuthProvider';

import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';

import Swal from 'sweetalert2';

import '../../styles/main/product.css';

const useMountedState = () => {
	const mountedRef = useRef(false);
	const isMounted = useCallback(() => mountedRef.current, []);

	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, [isMounted]);

	return isMounted;
};

const Product = () => {
	const { setUpdated, products } = useContext(AuthContext);
	const { productId } = useParams();

	const isMounted = useMountedState();
	const navigate = useNavigate();
	const location = useLocation();

	const [product, setProduct] = useState({});
	const [error, setError] = useState(false);
	const [colors, setColors] = useState([]);
	const [selectedColor, setSelectedColor] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		document.title = `Raycon Pool Covers | Product | ${productId}`;
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [location, productId]);

	useEffect(() => {
		try {
			const prod = products.find((product) => product.id === productId);

			if (!productId) {
				isMounted() && setError(true);
			} else {
				setError(false)
				isMounted() && setProduct(prod);
			}
		} catch (error) {
			isMounted() && setError(true);
		}
		finally {
			setLoading(false);
		}
		// eslint-disable-next-line
	}, [productId, isMounted, loading, products]);


	const loadColors = () => {
		try {
			if (product.Colors === undefined) {
				return;
			} else {
				let colors = product.Colors.split(',');

				let colorList = [];
				for (let i = 0; i < colors.length; i++) {
					colorList.push(colors[i]);
				}

				isMounted() && setColors(colorList);
			}
		} catch (error) {
			isMounted() && setError(true);
		}
		finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (productId !== undefined) {
			loadColors();
		}
		// eslint-disable-next-line
	}, [product]);


	const onSelectColor = (color) => {
		if (selectedColor === color) {
			setSelectedColor(null);
		} else {
			setSelectedColor(color);
		}
	};

	const onAddToCart = () => {
		if (product.Quantity > 0) {
			if (selectedColor === null) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Please select a color',
				});
			} else {
				let cart = JSON.parse(localStorage.getItem('cart'));

				if (cart === null) {
					cart = [];
				}

				let item = {
					id: product.id,
					name: product.Name,
					price: product.Price,
					color: selectedColor,
					image: product.Image,
					quantity: 1,
				};

				let itemExists = cart.find((item) => item.id === product.id);

				if (itemExists) {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Item already in cart',
					});
				} else {
					cart.push(item);
					localStorage.setItem('cart', JSON.stringify(cart));
					setUpdated(true);
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: 'Item added to cart',
					});
				}
			}
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: 'Out of stock',
			});
		}
	};

	const backToCatalog = () => {
		navigate('/shop');
	};

	return (
		<>
			<Nav />
			<section className='product-screen'>
				<div className='back' onClick={() => backToCatalog()}>
					Back To Catalog
				</div>
				<div className='main'>
					<div className='image-side'>
						<div className='image-container'>
							<img src={!error && product?.Image} alt='product' />
						</div>
					</div>
					<div className='info-side'>
						<h1>{!error ? product?.Name : 'Product Name'} </h1>

						<div className='description-container'>
							<h4>Description</h4>
							<p>{!error ? product?.Description : 'Product Description'}</p>
						</div>

						<div className='color-container'>
							<h4>Colors</h4>
							<div className='colors'>
								{colors.length > 0 &&
									colors.map((color, index) => (
										<div
											className={
												selectedColor === color ? 'color selected' : 'color'
											}
											key={index}
											onClick={() => onSelectColor(color)}
										>
											{color}
										</div>
									))}
							</div>
						</div>

						<div className='price'>
							<h4>Price</h4>
							<h2>R {!error ? product?.Price : '0.00'}</h2>
						</div>

						<div className='add-to-cart' onClick={() => onAddToCart()}>
							Add to Cart
						</div>
					</div>
				</div>
			</section>
			{loading && <Loader />}
			<Footer />
		</>
	);
};

export default Product;
