import React, { useState, useCallback, useContext, useEffect } from 'react';
import { AuthContext } from '../../navigation/AuthProvider';
import { Link, useNavigate } from 'react-router-dom';

import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import LabelInput from '../../components/LabelInput';

import '../../styles/auth/Password.css';

function Password() {
	const navigate = useNavigate();
	const { forgot, user } = useContext(AuthContext);
	const handleOnNavigation = useCallback(
		() => navigate('/login', { replace: true }),
		[navigate]
	);

	const [email, setEmail] = useState('');

	const handleChange = (event) => {
		setEmail(event.target.value);
	};

	const passwordChange = () => {
		if (email !== '') {
			forgot(email);
		} else {
			alert('Please enter your email address');
		}
	};

	useEffect(() => {
		if (user !== null) {
			navigate('/');
		}
		// eslint-disable-next-line
	}, [user]);

	return (
		<>
			<Nav />
			<div className='password-container'>
				<div className='container' id='container'>
					<div className='form-container sign-up-container '>
						<form autoComplete='off'>
							<h1 className='password-heading'>Reset Password</h1>

							<span className='small-text'>
								Enter your email and we'll send you a link to reset your
								password
							</span>

							<div className='tenpixels' />

							<LabelInput
								label={'Email'}
								onChangeText={handleChange}
								placeholder={'Email Address'}
								type={'email'}
								name={'email'}
								value={email}
								autoComplete={'none'}
							/>

							<div className='tenpixels' />

							<Button title='Reset Password' onPress={() => passwordChange()} />
						</form>
					</div>
					<div className='overlay-container'>
						<div className='overlay'>
							<div className='overlay-panel overlay-right'>
								<h1 className='password-heading white-text'>Hi There!</h1>
								<p className='password-text white-text'>
									Already have an account? Login here
								</p>

								<Button title='Back To Sign In' onPress={handleOnNavigation} />
							</div>
						</div>
					</div>
				</div>
				<div className='small-screen-login'>
					<form autoComplete='off'>
						<h1 className='password-heading'>Reset Password</h1>

						<span className='small-text'>
							Enter your email and we'll send you a link to reset your password
						</span>

						<div className='tenpixels' />

						<LabelInput
							label={'Email'}
							onChangeText={handleChange}
							placeholder={'Email Address'}
							type={'email'}
							name={'email'}
							value={email}
							autoComplete={'none'}
						/>

						<div className='tenpixels' />

						<Button title='Reset Password' onPress={() => passwordChange()} />

						<Link to='/login' className='password-link'>
							Already have an account? Login here
						</Link>
					</form>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Password;
