import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../navigation/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';

import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import Item from '../../components/Item';
import Empty from '../../components/Empty';

import product from '../../assets/images/image3.jpeg';

import '../../styles/main/shop.css';


const ShopScreen = () => {
	const { products } = useContext(AuthContext);
	// eslint-disable-next-line
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		document.title = `Raycon Pool Covers | Shop`;
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [location]);

	const onGoToProduct = (id) => {
		navigate(`/shop/${id}`, { replace: true });
	};

	return (
		<>
			<Nav />
			<section className='shop-screen'>
				<div className='container'>
					<div className='image-theme' />
					<img src={product} alt='product' />

					<div className='inner-container'>
						<h4>QUALITY SWIMMING COVERS</h4>
						<p>
							Keep your pool clean, keep your kids and pets safe with our Solid, High quality swimming pool covers.
							Same day response is guaranteed.
						</p>
						<p>
							SEND YOUR ORDER NOW!
						</p>
					</div>
				</div>
				<div className='main'>
					<div className='products'>
						{products.length > 0 ? (
							products.map((product) => (
								<Item
									key={product.id}
									name={product.Name}
									price={product.Price}
									img={product.Image}
									qty={product.Quantit}
									onPress={() => onGoToProduct(product.id)}
								/>
							))
						) : (
							<Empty text={'No Products'} />
						)}
					</div>
				</div>
			</section>
			<Footer />
		</>

	)
}

export default ShopScreen