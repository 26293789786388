import React, { useState, useEffect, useCallback, useRef, useContext, } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../navigation/AuthProvider';
import { IoImageOutline } from 'react-icons/io5';

import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import LabelInput from '../../components/LabelInput';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Photo from '../../components/Photo';

import '../../styles/main/dashboard.css';
import Swal from 'sweetalert2';

import { collection, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage } from '../../firebase';

const useMountedState = () => {
    const mountedRef = useRef(false);
    const isMounted = useCallback(() => mountedRef.current, []);

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, [isMounted]);

    return isMounted;
};

const DashboardScreen = () => {
    const { user, userData, logout, setUpdated, products, users } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useMountedState();
    const [loading, setLoading] = useState(false);
    const [add, setAdd] = useState(false);
    const [image, setImage] = useState(null);
    const [update, setUpdate] = useState(false);
    const [updateId, setUpdateId] = useState(null);
    const [values, setValues] = useState({
        name: '',
        price: '',
        description: '',
        colors: '',
        quantity: '',
    });

    useEffect(() => {
        document.title = `Raycon Pool Covers | Dashboard`;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location]);


    const [view, setView] = useState({
        show: false,
        image: null,
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleImage = (e) => {
        if (e === undefined) {
        } else {
            let file = e.target.files[0];
            var url = URL.createObjectURL(file);
            setImage({ url, file });
        }
    };

    useEffect(() => {
        if (user === null) {
            navigate('/login');
        } else if (userData !== null) {
            userData.Kind === 'user' && navigate('/dashboard');
        }
        // eslint-disable-next-line
    }, [user]);

    const uploadImageToStorage = async (file) => {
        try {
            const response = await fetch(file.url);
            const blob = await response.blob();

            const extension = file.file.name.split('.').pop();
            const name = file.file.name.split('.')[0];
            let filename = name + Date.now() + '.' + extension;

            const storageRef = ref(storage, `images/${filename}`);
            const snapshot = await uploadBytes(storageRef, blob);
            const downloadURL = await getDownloadURL(snapshot.ref);

            return downloadURL;
        } catch (error) {
            throw error;
        }
    };

    const onAddProduct = async () => {
        try {
            if (
                image !== null &&
                values.name !== '' &&
                values.price !== '' &&
                values.quantity !== ''
            ) {
                setLoading(true);
                const imageUrl = await uploadImageToStorage(image);

                if (imageUrl !== null) {
                    const productsCollection = collection(db, 'products');
                    addDoc(productsCollection, {
                        Name: values.name,
                        Price: values.price,
                        Description: values.description,
                        Colors: values.colors,
                        Quantity: values.quantity,
                        Image: imageUrl,
                        Date: new Date(),
                    }).then(() => {
                        setImage(null);
                        setValues({
                            name: '',
                            price: '',
                            description: '',
                            colors: '',
                            quantity: '',
                        });
                        setLoading(false);
                        setAdd(false);
                        setUpdated(true);
                        return Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Product added successfully',
                        });
                    });
                } else {
                    setLoading(false);
                    return Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong with the image upload process',
                    });
                }
            } else {
                return Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please fill all the required fields',
                });
            }
        } catch (error) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${error.message}`,
            });
        }
    };

    const handleScrollTo = () => {
        const element = document.getElementById('Add-Product');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const onShowImage = (image) => {
        setView({
            show: true,
            image: image,
        });
    };

    const onEdit = (product) => {
        handleScrollTo();
        setAdd(true);
        setUpdate(true);

        isMounted() &&
            setValues({
                name: product.Name,
                price: product.Price,
                description: product.Description,
                colors: product.Colors,
                quantity: product.Quantity,
            });
        setImage(product.Image);
        setUpdateId(product.id);
    };

    const onUpdateProduct = async () => {
        try {
            setLoading(true);
            const productRef = doc(db, 'products', updateId);
            await updateDoc(productRef, {
                Name: values.name,
                Price: values.price,
                Description: values.description,
                Colors: values.colors,
                Quantity: values.quantity,
                Updated: new Date(),
            }).then(() => {
                setImage(null);
                setValues({
                    name: '',
                    price: '',
                    description: '',
                    colors: '',
                    quantity: '',
                });
                setLoading(false);
                setAdd(false);
                setUpdate(false);
                setUpdateId(null);
                setUpdated(true);
                return Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Product updated successfully',
                });
            });
        } catch (error) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${error.message}`,
            });
        }
    };

    const onDeleteAlert = async ({ id, image }) => {
        return Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this product!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                onDeleteProduct({ id, image });
            }
        });
    };

    const onDeleteProduct = async ({ id, image }) => {
        try {
            setLoading(true);
            //delete image from firebase firestore
            const storageRef = ref(storage, image);
            await deleteObject(storageRef);

            //delete product from firebase firestore
            const productRef = doc(db, 'products', id);
            await deleteDoc(productRef).then(() => {
                setLoading(false);
                setUpdated(true);
                return Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Product deleted successfully',
                });
            });
        } catch (error) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${error.message}`,
            });
        }
    };

    const createInitials = (fullName) => {
        if (fullName === undefined) return;

        const names = fullName.trim().split(/\s+/);
        if (names.length === 1) {
            return names[0][0].toUpperCase();
        } else {
            return names.map(name => name[0].toUpperCase()).join('');
        }
    }

    const onCancelUpdate = () => {
        setAdd(false);
        setUpdate(false);
        setUpdateId(null);
        setImage(null);
        setValues({
            name: '',
            price: '',
            description: '',
            colors: '',
            quantity: '',
        });
    };

    return (
        <>
            <Nav />
            <section className='dashboard-screen'>
                <div className='main'>
                    <div className='initials'>
                        <p>{userData && createInitials(userData?.Name)}</p>
                    </div>
                    <div className='name'>Hi, {userData && userData.Name}!</div>
                    <div className='email'>{userData && userData.Email}</div>

                    <div className='logout' onClick={logout}>
                        Logout
                    </div>
                </div>
                {userData?.Kind === 'admin' && (
                    <>
                        <div className='admin'>
                            <div className='title'>Overview</div>
                            <div className='overview'>
                                <div className='numbers'>
                                    <p>Total Product Amount</p>
                                    <h3>
                                        R{' '}
                                        {products.length > 0
                                            ? products.reduce((acc, prod) => acc + Number(prod.Price), 0)
                                            : '00.00'}
                                    </h3>
                                </div>
                                <div className='numbers'>
                                    <p>Number of Products</p>
                                    <h3>{products.length > 0 ? products.length : '0'}</h3>
                                </div>
                                <div className='numbers'>
                                    <p>Email List</p>
                                    <h3>{users.length > 0 ? users.length : '0'}</h3>
                                </div>
                            </div>
                        </div>
                        <div className='admin'>
                            <div
                                className='title clickable-title'
                                onClick={() => setAdd(!add)}
                                id='Add-Product'
                            >
                                Add Product
                            </div>

                            <div className={add ? 'add-product' : 'add-product hidden'}>
                                <div className='add-form'>
                                    <div className='more_input'>
                                        <LabelInput
                                            label={'Product Name *'}
                                            onChangeText={handleChange}
                                            placeholder={'name of the product'}
                                            type={'text'}
                                            name={'name'}
                                            value={values.name}
                                            autoComplete={'none'}
                                        />
                                        <LabelInput
                                            label={'Product Price (without R) *'}
                                            onChangeText={handleChange}
                                            placeholder={'00.00'}
                                            type={'text'}
                                            name={'price'}
                                            value={values.price}
                                            autoComplete={'none'}
                                        />
                                        <LabelInput
                                            label={'Product Description'}
                                            onChangeText={handleChange}
                                            placeholder={'write a description for the product'}
                                            type={'text'}
                                            name={'description'}
                                            value={values.description}
                                            autoComplete={'none'}
                                        />
                                        <LabelInput
                                            label={'Available Colors'}
                                            onChangeText={handleChange}
                                            placeholder={'red, blue, green, yellow'}
                                            type={'text'}
                                            name={'colors'}
                                            value={values.colors}
                                            autoComplete={'none'}
                                        />
                                        <LabelInput
                                            label={'Product Quantity *'}
                                            onChangeText={handleChange}
                                            placeholder={'23'}
                                            type={'number'}
                                            name={'quantity'}
                                            value={values.quantity}
                                            autoComplete={'none'}
                                        />
                                        {update ? (
                                            <>
                                                <Button
                                                    title='Update Product'
                                                    onPress={() => onUpdateProduct()}
                                                />
                                                <Button
                                                    title='Cancel Update'
                                                    onPress={() => onCancelUpdate()}
                                                />
                                            </>
                                        ) : (
                                            <Button title='Add Product' onPress={() => onAddProduct()} />
                                        )}
                                    </div>
                                    <div className='upload'>
                                        <p className='label'>Product Image *</p>
                                        <div className='upload_body'>
                                            {image !== null ? (
                                                <div className='image-container'>
                                                    <img src={update ? image : image.url} alt='product' />
                                                </div>
                                            ) : (
                                                <>
                                                    <IoImageOutline className='upload_icon' />
                                                    <p>Drag an image here to upload</p>
                                                </>
                                            )}
                                        </div>
                                        <div className='upload_footer'>
                                            <label htmlFor='upload_input' className='upload_button'>
                                                <p>
                                                    <span>or</span> choose file
                                                </p>
                                                <input
                                                    type='file'
                                                    id='upload_input'
                                                    onChange={(e) => handleImage(e)}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='admin'>
                            <div className='title'>Products</div>
                            <div className='products'>
                                <div className='products-header'>
                                    <div className='product'>Product</div>
                                    <div className='product-price'>Price</div>
                                    <div className='product-quantity'>Quantity</div>
                                    <div className='product-action'>Action</div>
                                </div>
                                {products.length > 0 ? (
                                    products.map((product) => (
                                        <div className='products-body' key={product.id}>
                                            <div className='product'>
                                                <h2>{product.Name}</h2>
                                                <p>
                                                    Colors: <span>{product.Colors}</span>
                                                </p>
                                            </div>
                                            <div
                                                className='image-link'
                                                onClick={() => onShowImage(product.Image)}
                                            >
                                                View Image
                                            </div>
                                            <div className='product-price'>R {product.Price}</div>
                                            <div className='product-quantity'>
                                                {product.Quantity} Available
                                            </div>
                                            <div className='product-action'>
                                                <p onClick={() => onEdit(product)}>Edit</p>
                                                <p
                                                    onClick={() =>
                                                        onDeleteAlert({ id: product.id, image: product.Image })
                                                    }
                                                >
                                                    Delete
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='empty-list'>
                                        <p>No products available.</p>
                                        <p>Add a product to get started.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </section>
            {loading && <Loader />}
            {view.show && (
                <Photo
                    image={view}
                    onClose={() =>
                        setView({
                            show: false,
                            image: null,
                        })
                    }
                />
            )}
            <Footer />
        </>
    )
}

export default DashboardScreen