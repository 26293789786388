import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, getDocs, addDoc, query, where } from "firebase/firestore";
import { db } from '../../firebase';

import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import Button from '../../components/Button';

import '../../styles/main/home-main.css';
import '../../styles/main/newsletter.css';
import '../../styles/main/collection.css';

import main from '../../assets/images/image8.jpeg';
import image from '../../assets/images/image4.jpeg';
import imageII from '../../assets/images/image18.jpg';
import imageIII from '../../assets/images/image17.jpg';

import Swal from 'sweetalert2';

const HomeScreen = () => {
    // eslint-disable-next-line
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');

    useEffect(() => {
        document.title = `Raycon Pool Covers | Home`;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location]);

    const onSubmit = async () => {
        if (email !== '') {
            if (!/\S+@\S+\.\S+/.test(email)) {
                Swal.fire(
                    'Error',
                    `Please enter a valid email address.`,
                    'error'
                )
            }
            else {
                const Email = email.toLowerCase();
                let flag = false;

                const emailListRef = collection(db, 'EmailList');
                const q = query(emailListRef, where('Email', '==', Email));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    if (doc.exists()) {
                        flag = true;
                    }
                });

                if (!flag) {
                    await addDoc(emailListRef, {
                        Email,
                        Created: Date.now(),
                    });

                    setEmail('');
                    Swal.fire(
                        'Submitted',
                        `Thank you for subscribing to our email list.`,
                        'success'
                    );
                } else {
                    setEmail('');
                    Swal.fire('Info', `You are already in our email list.`, 'info');
                }
            }
        }
        else {
            Swal.fire(
                'Error',
                `Email address cannot be empty.`,
                'error'
            )
        }
    };


    return (
        <>
            <Nav />
            <section className='home-screen'>
                <img src={main} alt='home-main' className='main-image' />
                <div className='main'>
                    <div className='textcontainer'>
                        <h5 className='title'>
                            QUALITY SAFETY COVER FOR YOUR POOL
                        </h5>
                        <p>
                            Find the best quality Swimming pool cover for your pool at Raycon swimming pool covers.
                            Let your kids and pets be protected with us at Rayon swimming pool covers.
                        </p>
                    </div>
                    <div className='buttoncontainer'>
                        <Button
                            title='Shop Now'
                            onPress={() => navigate('./shop')}
                        />
                    </div>
                </div>
            </section>

            <section className='collection-screen'>
                <div className='container'>
                    <div className='left'>
                        <div className='side-images'>
                            <img src={imageII} alt='collection' />
                        </div>
                    </div>
                    <div className='middle'>
                        <div className='collection-text'>
                            <h2>Pool</h2> <h2>Covers</h2>
                        </div>
                        <div className='middle-image' onClick={() => navigate('/shop')}>
                            <img src={image} alt='collection' />
                            <div className='overlay'>
                                <p>SEE MORE</p>
                            </div>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='side-images'>
                            <img src={imageIII} alt='collection' />
                        </div>
                    </div>
                </div>
            </section>

            <section className='newsletter-screen'>
                <div className='container'>
                    <p>Subscribe to our newsletter</p>
                    <div className='input-container'>
                        <input type='email' placeholder='Enter your email address' onChange={(e) => setEmail(e.target.value)} value={email} />
                        <div className='button' onClick={() => onSubmit()}>
                            <span>Subscribe</span>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default HomeScreen