import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../navigation/AuthProvider';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import Button from '../../components/Button';
import LabelInput from '../../components/LabelInput';
import Loading from '../../components/Loader';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

import Swal from 'sweetalert2';

import '../../styles/auth/Login.css';


function LoginScreen() {
    const navigate = useNavigate();
    const location = useLocation();
    const { login, register, loading, user } = useContext(AuthContext);
    const [right, setRight] = useState(false);
    const [smallLogin, setSmallLogin] = useState(false);
    // eslint-disable-next-line
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    useEffect(() => {
        if (user !== null) {
            navigate('/dashboard');
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        document.title = `Raycon Pool Covers | Login`;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location]);

    const onLogin = () => {
        if (values.email !== '' && values.password !== '') {
            login(values.email, values.password);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter all the fields',
            });
        }
    };

    const onSignUp = () => {
        const error = validateInput();

        if (Object.keys(error).length === 0) {
            register(values.name, values.email, values.password);
        } else {
            setErrors(error);
            if (error.name) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.name,
                });
            } else if (error.email) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.email,
                });
            } else if (error.password) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.password,
                });
            } else if (error.passwordLength) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.passwordLength,
                });
            } else if (error.passwordLowerCase) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.passwordLowerCase,
                });
            } else if (error.passwordUpperCase) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.passwordUpperCase,
                });
            } else if (error.passwordNumber) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.passwordNumber,
                });
            }
        }
    };

    const validateInput = () => {
        let errors = {};
        var lowerCaseLetters = /[a-z]/g;
        var upperCaseLetters = /[A-Z]/g;
        var numbers = /[0-9]/g;

        if (!values.name) {
            errors.name = 'Name is required';
        }

        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            errors.email = 'Invalid Email';
        }

        if (!values.password) {
            errors.password = 'Password is required';
        }

        if (values.password.length < 8) {
            errors.passwordLength = 'Password must be at least 8 characters';
        }

        if (!values.password.match(lowerCaseLetters)) {
            errors.passwordLowerCase =
                'Password must contain at least one lowercase letter';
        }

        if (!values.password.match(upperCaseLetters)) {
            errors.passwordUpperCase =
                'Password must contain at least one uppercase letter';
        }

        if (!values.password.match(numbers)) {
            errors.passwordNumber = 'Password must contain at least one number';
        }

        return errors;
    };

    useEffect(() => {
        setErrors({});
    }, [values]);

    return (
        <>
            <Nav />
            <div className='login'>
                <div
                    className={right ? 'container right-panel-active' : 'container'}
                    id='container'
                >
                    <div className='form-container sign-up-container '>
                        <form autoComplete='off'>
                            <h1 className='login-heading'>Create Account</h1>

                            <span className='small-text'>
                                Please use your email for registration
                            </span>

                            <LabelInput
                                label={'Full Name'}
                                onChangeText={handleChange}
                                placeholder={'Full Name'}
                                type={'text'}
                                name={'name'}
                                value={values.name}
                                autoComplete={'none'}
                            />

                            <LabelInput
                                label={'Email'}
                                onChangeText={handleChange}
                                placeholder={'Email Address'}
                                type={'email'}
                                name={'email'}
                                value={values.email}
                                autoComplete={'none'}
                            />
                            <LabelInput
                                label={'Password'}
                                onChangeText={handleChange}
                                placeholder={'Password'}
                                type={'password'}
                                name={'password'}
                                value={values.password}
                                autoComplete={'new-password'}
                            />

                            <div className='tenpixels' />

                            <Button title='Sign Up' onPress={() => onSignUp()} />
                        </form>
                    </div>
                    <div className='form-container sign-in-container'>
                        <form autoComplete='off'>
                            <h1 className='login-heading'>Sign in</h1>

                            <span className='small-text'>Please use your account</span>
                            <LabelInput
                                label={'Email'}
                                onChangeText={handleChange}
                                placeholder={'Email Address'}
                                type={'email'}
                                name={'email'}
                                value={values.email}
                                autoComplete={'none'}
                            />
                            <LabelInput
                                label={'Password'}
                                onChangeText={handleChange}
                                placeholder={'Password'}
                                type={'password'}
                                name={'password'}
                                value={values.password}
                                autoComplete={'new-password'}
                            />

                            <Link to='/password' className='login-link'>
                                Forgot your password?
                            </Link>
                            <Button title='Sign In' onPress={() => onLogin()} />
                        </form>
                    </div>
                    <div className='overlay-container'>
                        <div className='overlay'>
                            <div className='overlay-panel overlay-left'>
                                <h1 className='login-heading'>Welcome Back!</h1>
                                <p className='login-text'>
                                    If you already have an account, please sign in
                                </p>

                                <Button title='Sign In' onPress={() => setRight(!right)} />
                            </div>
                            <div className='overlay-panel overlay-right'>
                                <h1 className='login-heading white-text'>Hey Welcome!</h1>
                                <p className='login-text white-text'>
                                    Open an account to get started
                                </p>

                                <Button
                                    title='Open An Account'
                                    onPress={() => setRight(!right)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {smallLogin ? (
                    <div className='small-screen-login'>
                        <form autoComplete='off'>
                            <h1 className='login-heading'>Sign in</h1>

                            <span className='small-text'>Please use your account</span>
                            <LabelInput
                                label={'Email'}
                                onChangeText={handleChange}
                                placeholder={'Email Address'}
                                type={'email'}
                                name={'email'}
                                value={values.email}
                                autoComplete={'none'}
                            />
                            <LabelInput
                                label={'Password'}
                                onChangeText={handleChange}
                                placeholder={'Password'}
                                type={'password'}
                                name={'password'}
                                value={values.password}
                                autoComplete={'new-password'}
                            />

                            <Link to='/password' className='login-link'>
                                Forgot your password?
                            </Link>
                            <Button title='Sign In' onPress={() => onLogin()} />

                            <p
                                className='login-link'
                                onClick={() => setSmallLogin(!smallLogin)}
                            >
                                Don't have an account? Sign Up
                            </p>
                        </form>
                    </div>
                ) : (
                    <div className='small-screen-login'>
                        <form autoComplete='off'>
                            <h1 className='login-heading'>Create Account</h1>

                            <span className='small-text'>
                                Please use your email for registration
                            </span>

                            <LabelInput
                                label={'Full Name'}
                                onChangeText={handleChange}
                                placeholder={'Full Name'}
                                type={'text'}
                                name={'name'}
                                value={values.name}
                                autoComplete={'none'}
                            />

                            <LabelInput
                                label={'Email'}
                                onChangeText={handleChange}
                                placeholder={'Email Address'}
                                type={'email'}
                                name={'email'}
                                value={values.email}
                                autoComplete={'none'}
                            />
                            <LabelInput
                                label={'Password'}
                                onChangeText={handleChange}
                                placeholder={'Password'}
                                type={'password'}
                                name={'password'}
                                value={values.password}
                                autoComplete={'new-password'}
                            />

                            <div className='tenpixels' />

                            <Button title='Sign Up' onPress={() => onSignUp()} />
                            <p
                                className='login-link'
                                onClick={() => setSmallLogin(!smallLogin)}
                            >
                                Already have an account? Sign In
                            </p>
                        </form>
                    </div>
                )}
            </div>
            {loading && <Loading />}
            <Footer />
        </>
    );
}

export default LoginScreen;