import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import LabelInput from '../../components/LabelInput';
import LabelTextArea from '../../components/LabelTextArea';
import Loader from '../../components/Loader';

import image from '../../assets/images/image3.jpeg';
import logo from '../../assets/images/Logo.jpeg';

import '../../styles/main/contact.css';
import Swal from 'sweetalert2';

import axios from 'axios';


const useMountedState = () => {
    const mountedRef = useRef(false);
    const isMounted = useCallback(() => mountedRef.current, []);

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    return isMounted;
};

const ContactScreen = () => {
    const isMounted = useMountedState();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const handleChange = (event) => {
        if (isMounted()) {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleSubmit = async () => {
        if (!values.name || !values.email || !values.phone || !values.message) {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all the fields',
            });
        } else {
            try {
                setLoading(true);
                await axios.post('https://formspree.io/f/xdoqkezv', values);

                setValues({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                })
                return Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Message sent successfully',
                })

            } catch (error) {
                return Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${error.message}`,
                });
            }
            finally {
                setLoading(false);
            }
        }

    };

    useEffect(() => {
        document.title = `Raycon Pool Covers | Contact Us`;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location]);

    return (
        <>
            <Nav />
            <section className='contact-screen'>
                <div className='container'>
                    <div className='image-theme' />
                    <img src={image} alt='contact' />

                    <div className='inner-container'>
                        <h4>CONTACT US</h4>
                    </div>
                </div>
                <div className='main'>
                    <div className='info'>
                        <div className='imagecontainer'>
                            <img src={logo} alt='logo' />
                        </div>
                        <h6>
                            If you have any questions or inquiries, please leave us a message or call us at 0721725027.
                            We are eager to respond to your queries and arrange a meeting with you.
                        </h6>

                        <div className='flex'>
                            <LabelInput
                                label={'Your Name *'}
                                placeholder={'Enter your name'}
                                type={'text'}
                                name={'name'}
                                onChangeText={handleChange}
                                value={values.name}
                                autoComplete={'none'}
                            />
                            <LabelInput
                                label={'Contact Email *'}
                                placeholder={'Enter your email'}
                                type={'text'}
                                name={'email'}
                                onChangeText={handleChange}
                                value={values.email}
                                autoComplete={'none'}
                            />
                        </div>
                        <div className='flex'>
                            <LabelInput
                                label={'Contact Number *'}
                                placeholder={'Enter your number'}
                                type={'text'}
                                name={'phone'}
                                onChangeText={handleChange}
                                value={values.phone}
                                autoComplete={'none'}
                            />
                            <LabelInput
                                label={'Subject *'}
                                placeholder={'Enter your subject'}
                                type={'text'}
                                name={'subject'}
                                onChangeText={handleChange}
                                value={values.subject}
                                autoComplete={'none'}
                            />
                        </div>
                        <div className='flex'>
                            <LabelTextArea
                                label={'Message *'}
                                placeholder={'Enter your message'}
                                type={'text'}
                                name={'message'}
                                onChangeText={handleChange}
                                value={values.message}
                                autoComplete={'none'}
                            />
                        </div>
                        <div className='btncontainer'>
                            <Button title='Send message' onPress={() => handleSubmit()} />
                        </div>
                    </div>
                </div>

            </section >
            {loading && <Loader />}
            <Footer />
        </>
    )
}

export default ContactScreen