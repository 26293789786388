import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
	apiKey: "AIzaSyAep6V_P2mlZA4nZ7jy2N70CFDFr67bQJw",
	authDomain: "raycon-pc.firebaseapp.com",
	projectId: "raycon-pc",
	storageBucket: "raycon-pc.appspot.com",
	messagingSenderId: "1087389504400",
	appId: "1:1087389504400:web:408e76bc60042299b55915",
	measurementId: "G-72J7JPGV4P"
};

const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
