import React from 'react';
import './styles/button.css';

const Button = ({ title, onPress }) => {
	return (
		<div className='buttonComponent' onClick={onPress}>
			<p className='text'>{title}</p>
		</div>
	);
};

export default Button;
