import React from 'react';
import './styles/cart-item.css';
import { IoAddSharp, IoRemoveSharp } from 'react-icons/io5';

const CartItem = ({ product, onRemove, onAdd }) => {
	return (
		<div className='cart-item' key={product.id}>
			<div className='image-container'>
				<img src={product.image} alt='product' />
			</div>
			<div className='item-details'>
				<h3>{product.name}</h3>
				<p>
					{product.size} | {product.color}
				</p>
				<div className='qty-price'>
					<div className='quantity'>
						<IoRemoveSharp className='quantity-btn' onClick={onRemove} />
						<p>{product.quantity}</p>
						<IoAddSharp className='quantity-btn' onClick={onAdd} />
					</div>
					<h4>R {product.price}</h4>
				</div>
			</div>
		</div>
	);
};

export default CartItem;
