import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Nav from '../../components/Nav';
import Footer from '../../components/Footer';

import image from '../../assets/images/image3.jpeg';
import logo from '../../assets/images/Logo.jpeg';

import '../../styles/main/about.css';

const AboutScreen = () => {
    // eslint-disable-next-line
    const location = useLocation();

    useEffect(() => {
        document.title = `Raycon Pool Covers | About Us`;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [location]);

    return (
        <>
            <Nav />
            <section className='about-screen'>
                <div className='container'>
                    <div className='image-theme' />
                    <img src={image} alt='about' />

                    <div className='inner-container'>
                        <h4>ABOUT US</h4>
                    </div>
                </div>
                <div className='main'>
                    <div className='info'>
                        <h4>Raycon Pool Covers</h4>
                        <p>
                            Raycon has been providing solid, high-quality pool covers.
                            We strive to provide 100% safety for kids and pets and provide peace of mind to parents.
                            Our covers help keep swimming pools clean and safe.
                        </p>
                        <div className='imagecontainer'>
                            <img src={logo} alt='logo' />
                        </div>
                        <p>
                            Raycon is a 100% black-owned and managed company.
                            The founder and director, including management, have made more than 25 years of combined experience within various Risk management, Logistics, Project management, and Operations.
                            Raycon is based in Johannesburg, South Africa. Since the company was founded, the core focus has been on the 4th Industrial Revolution and remaining relevant in the industry.
                        </p>

                        <h5>
                            Our Vision and Mission
                        </h5>
                        <p>
                            Our Vision and Mission is to become one of the reputable organizations that strive to offer a one-stop solution for our clients.
                            To represent the aspirations, dreams, and aims of professional women and youth in business.
                            We strive to be an organization that contributes significantly to the South African and African continent economy.
                            The focus is not only to deliver Goods and Services. We add value by empowering our clients through sharing best practices, cost-saving mechanisms, and collaboration.
                        </p>

                        <h5>
                            Our Values
                        </h5>
                    </div>
                </div>
                <div className='values'>
                    <div className='value'>
                        <h5>Innovation</h5>
                        <p>
                            We believe that with Technology, anything is possible, and we keep challenging to provide turnkey solutions.
                        </p>
                    </div>
                    <div className='value'>
                        <h5>Reliability</h5>
                        <p>
                            We provide reliable services and solutions to our customers.
                        </p>
                    </div>
                    <div className='value'>
                        <h5>Integrity</h5>
                        <p>
                            Our solutions undergo rigorous testing, and services are dynamic.
                        </p>
                    </div>
                    <div className='value'>
                        <h5>Honesty</h5>
                        <p>
                            We built long-lasting relationships with our clients, and honesty is our only policy
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default AboutScreen