import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from '../pages/main/HomeScreen';
import Shop from '../pages/main/ShopScreen';
import About from '../pages/main/AboutScreen';
import Contact from '../pages/main/ContactScreen';
import Product from '../pages/main/ProductScreen';

import LoginScreen from '../pages/auth/LoginScreen';
import PasswordScreen from '../pages/auth/PasswordScreen';
import DashboardScreen from '../pages/main/DashboardScreen';


const AppStack = () => {
	return (
		<>
			<Router>
				<Routes>
					<Route path='/' exact element={<Home />} />
					<Route path='/shop' element={<Shop />} />
					<Route path='/shop/:productId' element={<Product />} />
					<Route path='/about' element={<About />} />
					<Route path='/contact' element={<Contact />} />

					<Route path='/login' element={<LoginScreen />} />
					<Route path='/password' element={<PasswordScreen />} />
					<Route path='/dashboard' element={<DashboardScreen />} />

					<Route path='*' element={<Home />} />
				</Routes>
			</Router>
		</>
	);
};

export default AppStack;
