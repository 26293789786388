import React, { useRef, useEffect, useState, useContext } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FiHome, FiShoppingCart, FiUsers, FiPhone, FiX, FiMenu, FiGrid } from "react-icons/fi";
import { AuthContext } from '../navigation/AuthProvider';

import './styles/nav.css';

import logo from '../assets/images/logo-bg.png';
import Cart from './Cart';
import Swal from 'sweetalert2';


const Nav = () => {
	const { updated, setUpdated, openCart, setOpenCart, userData } = useContext(AuthContext);
	const [active, setActive] = useState('');
	const [click, setClick] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const [cartActive, setCartActive] = useState(false);
	const [count, setCount] = useState(0);
	const location = useLocation();
	const header = useRef();
	const navigate = useNavigate();
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(!click);


	useEffect(() => {
		const path = location.pathname.split('/')[1];

		path !== '' ? setActive(path) : setActive('home');
	}, [location]);

	const onNavigate = (path) => {
		navigate(path);
	};

	const toggleCart = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});

		if (window.screenY === 0) {
			setCartActive(!cartActive);
		} else {
			toggleCart();
		}
	};

	useEffect(() => {
		updated && setUpdated(false);
		let cart = JSON.parse(localStorage.getItem('cart'));

		if (cart === null) {
			setCount(0);
		} else {
			setCount(cart.length);
		}
	}, [updated, setUpdated, cartActive]);

	const generateWhatsAppMessage = (cart) => {
		let message = "Hey, I would like to order: \n";
		let total = 0;

		cart.forEach(item => {
			const lineTotal = item.price * item.quantity;
			total += lineTotal;
			message +=
				`${item.name.trim()} x ${item.quantity} = R${lineTotal}\nhttps://rayconpoolcovers.com/shop/${item.id}\n\n`;
		});

		message += `Total: R${total}`;
		return encodeURI(message);
	};

	const sendToWhatsApp = (message) => {
		const whatsappNumber = "+27721725027"; // +27721725027
		const url = `https://wa.me/${whatsappNumber}?text=${message}`;
		window.open(url, "_blank");
	};

	const handleOrderSubmit = (cart) => {
		const message = generateWhatsAppMessage(cart);
		sendToWhatsApp(message);
		localStorage.clear();
		setUpdated(true);
		navigate('/', { replace: true });
		return Swal.fire({
			icon: 'success',
			title: 'Thank you',
			text: `Please continue your order on WhatsApp.`,
		});
	};


	useEffect(() => {
		if (openCart) {
			setOpenCart(false);
			toggleCart();
		}
		// eslint-disable-next-line
	}, [openCart]);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 100) {
				setIsScrolled(true);
			} else {
				setIsScrolled(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<header className={isScrolled ? 'navbar scrolled' : 'navbar'} ref={header}>
			<div className='container'>
				<div className='logo' onClick={() => onNavigate('/')}>
					<img src={logo} alt='logo' />
				</div>
				{click ? (
					<FiX className='menu-icon' onClick={handleClick} />
				) : (
					<FiMenu className='menu-icon' onClick={handleClick} />
				)}

				<div className={click ? 'nav-right active' : 'nav-right'}>
					<NavLink
						to='/'
						className={active === 'home' ? 'active navlink' : 'navlink'}
						onClick={closeMobileMenu}
					>
						<FiHome className='nav-icon' />
						<span>Home</span>
					</NavLink>
					<NavLink
						to='/shop'
						className={active === 'catalog' ? 'active navlink' : 'navlink'}
						onClick={closeMobileMenu}
					>
						<FiShoppingCart className='nav-icon' />
						<span>Shop</span>
					</NavLink>

					{userData?.Kind === 'admin' ?
						(
							<NavLink
								to='/dashboard'
								className={active === 'catalog' ? 'active navlink' : 'navlink'}
								onClick={closeMobileMenu}
							>
								<FiGrid className='nav-icon' />
								<span>dashboard</span>
							</NavLink>
						)
						: (
							<NavLink
								to='/about'
								className={active === 'catalog' ? 'active navlink' : 'navlink'}
								onClick={closeMobileMenu}
							>
								<FiUsers className='nav-icon' />
								<span>About Us</span>
							</NavLink>

						)
					}

					<NavLink
						to='/contact'
						className={active === 'catalog' ? 'active navlink' : 'navlink'}
						onClick={closeMobileMenu}
					>
						<FiPhone className='nav-icon' />
						<span>Contact Us</span>
					</NavLink>

					<div className='cart' onClick={toggleCart}>
						<div className='cart-icon-container'>
							<FiShoppingCart className='cart-icon' />
						</div>

						<p className='cart-number'>{count}</p>
					</div>
				</div>
			</div>
			<Cart
				status={cartActive}
				close={() => setCartActive(!cartActive)}
				goToCheckOut={(cart) => handleOrderSubmit(cart)}
			/>
		</header>
	);
};

export default Nav;
