import React from 'react';
import './styles/empty.css';

const Empty = ({ text }) => {
	return (
		<div className='empty'>
			<h1>{text}</h1>
		</div>
	);
};

export default Empty;
