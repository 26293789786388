import React from 'react';
import './styles/loader.css';

const Loader = () => {
	return (
		<>
			<div className='loader-container'>
				<div className='loader-wrapper'>
					<div className='loader' />
				</div>
			</div>
		</>
	);
};

export default Loader;
