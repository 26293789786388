import React from 'react';
import './styles/photo.css';
import { IoCloseCircleOutline } from 'react-icons/io5';

const Photo = ({ image, onClose }) => {
	return (
		<div className='photo-overlay'>
			<div className='container'>
				<div className='photo-wrapper'>
					<div className='close-btn' onClick={onClose}>
						<IoCloseCircleOutline className='icon' />
					</div>
					<img src={image.image} alt='product' />
					<div className='load-wrapper'>
						<div className='loader' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Photo;
