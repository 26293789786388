import React from 'react';
import './styles/labelinput.css';

const LabelInput = ({
	label,
	onChangeText,
	placeholder,
	type,
	value,
	name,
	autoComplete,
}) => {
	return (
		<div className='label-input-component'>
			<p className='label'>{label}</p>
			<div className='input-container'>
				<input
					autoComplete={autoComplete}
					className='input'
					name={name}
					onChange={onChangeText}
					placeholder={placeholder}
					value={value}
					type={type}
					autofill='off'
				/>
			</div>
		</div>
	);
};

export default LabelInput;
